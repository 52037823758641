import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, } from '@mui/material';

//
import AccountPopover from './AccountPopover';
import { getGstTasks } from '../../../STORE/MiddleWare/GstTask';
import { UseLoginToken } from '../../../STORE/Selector/Auth';
import { loaderHide, loaderShow, updateViewMonth } from '../../../STORE/Slice/Utils';
import { UseViewMonth, UseHeaderCalendar } from '../../../STORE/Selector/Utils';
import { ShowMessage } from '../../../components/Utils';

// utils
import { bgBlur } from '../../../utils/cssStyles';
import InputContainer from '../../../components/InputContainer/InputContainer';

// ----------------------------------------------------------------------

const NAV_WIDTH = 180;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Header() {
  const [viewMonth, setViewMonth] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();

  // Custom Selector Hooks
  const CurrentViewMonth = UseViewMonth();
  const ViewCalendar = UseHeaderCalendar();

  useEffect(() => {
    if (CurrentViewMonth?.month && CurrentViewMonth?.year) {
      setViewMonth(moment(`${CurrentViewMonth?.month}-${CurrentViewMonth?.year}`, "MM-YYYY"))
    }
  }, [CurrentViewMonth?.month, CurrentViewMonth?.year])

  const MonthChangeHandler = (event) => {
    dispatch(updateViewMonth(event.target.value));
  };


  return (
    <StyledRoot>
      <StyledToolbar>
        {ViewCalendar.includes(location?.pathname.replace("/", "")) ?
          <InputContainer
            type="date"
            id="overallmonth"
            format="MMMM-YYYY"
            value={viewMonth}
            name="overallmonth"
            picker="month"
            onChange={MonthChangeHandler}
            placeholder="Enter Month"
            containerClassName="pe-3 pb-1 w-170-px"
          />
          : null}
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
