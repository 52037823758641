import { createSlice } from '@reduxjs/toolkit';
import { getClients, getClient, createClient, } from '../MiddleWare/Clients';

const initialState = {
  clientsData: [],
  month: '',
  year: null,
  client: null,
  error: '',
  loading: false,
};

const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getClients.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getClients.fulfilled, (state, action) => {
      if (action.payload) {
        state.clientsData = action.payload;
      }
      state.loading = false;
    });

    builder.addCase(getClients.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = false;
      }
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getClient.pending, () => { });

    builder.addCase(getClient.fulfilled, (state, action) => {
      if (action.payload) {
        state.client = action.payload.client;
      }
    });

    builder.addCase(getClient.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(createClient.pending, () => { });

    builder.addCase(createClient.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload.client;
      }
    });

    builder.addCase(createClient.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });
  },
});

export default clientSlice.reducer;
