import InputContainer from '../../../components/InputContainer/InputContainer';

const ClientDetails = ({ mode, drawerType, data, formErrors, handleChange }) => (
  <>
    <div className="d-flex">
      <InputContainer
        required
        label="Client Name"
        type="text"
        id="clientName"
        name="clientName"
        error={formErrors?.clientName}
        value={data?.clientName}
        onChange={handleChange}
        placeholder="Enter Client Name"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && drawerType === 'existing')}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Date of Birth"
        type="date"
        id="date"
        format="DD-MMM-YYYY"
        value={data?.dob}
        name="dob"
        picker="date"
        onChange={handleChange}
        placeholder="Enter Date of Birth"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
    </div>
    <div className="d-flex">
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Aadhaar No"
        type="text"
        id="aadhaarNo"
        name="aadhaarNo"
        value={data?.aadhaarNo}
        onChange={handleChange}
        placeholder="Enter Aadhaar No"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="PAN"
        type="text"
        id="pan"
        name="pan"
        value={data?.pan}
        onChange={handleChange}
        placeholder="Enter PAN No"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
    </div>
    <div className="d-flex">
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Client Mobile"
        type="number"
        id="clientMobile"
        name="clientMobile"
        value={data?.clientMobile}
        onChange={handleChange}
        placeholder="Enter Client Mobile"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Identity"
        type="text"
        id="identity"
        name="identity"
        value={data?.identity}
        onChange={handleChange}
        placeholder="Enter Identity"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
    </div>
  </>
);

export default ClientDetails;
