import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';

// Component
import { frameFilterArray } from '../components/Utils';
import SearchTable from '../components/Table/SearchTable';
import DrawerGstTask from '../sections/gstTask/DrawerGstTask';

// Utils
import { UseGstTasks } from '../STORE/Selector/GstTask';
import { getGstTasks } from '../STORE/MiddleWare/GstTask';
import { UseViewMonth } from '../STORE/Selector/Utils';

// CSS
import '../css/Clients.scss';

const GstTask = () => {
  const dispatch = useDispatch();

  // State
  const [show, setShow] = useState(false);
  const [userType, setUserType] = useState('new');
  const [rowValue, setRowValue] = useState({});
  const [gstTaskData, setGstTaskData] = useState([]);

  // Custom Selector Hooks
  const gstTasks = UseGstTasks();
  const CurrentViewMonth = UseViewMonth();

  useEffect(() => {
    if (CurrentViewMonth?.month && CurrentViewMonth?.year) {
      dispatch(getGstTasks({ month: CurrentViewMonth?.month, year: CurrentViewMonth?.year }));
    }
  }, [CurrentViewMonth?.month, CurrentViewMonth?.year]);

  useEffect(() => {
    setGstTaskData(gstTasks?.GstTask);
  }, [gstTasks]);

  const onRowClickHandler = (e, value) => {
    setShow(true);
    setUserType('existing');
    setRowValue(value);
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 's.no',
      align: 'center',
      width: '50px',
      onCell: () => ({ className: '' }),
      render: (value, row, index) => (row.page - 1) * 10 + (index + 1),
      fixed: 'left',
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      align: 'center',
      width: '100px',
      onCell: () => ({ className: 'text-left ' }),
      render(text) {
        return <div>{text}</div>;
      },
      fixed: 'left',
      filters: frameFilterArray(gstTaskData, "clientName"),
      onFilter: (value, record) => record.clientName === value,
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
    },
    {
      title: 'Acknowledgement No',
      dataIndex: 'acknowledgementNo',
      key: 'acknowledgementNo',
      align: 'center',
      width: '130px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Mode of Payment',
      dataIndex: 'modeOfPayment',
      key: 'modeOfPayment',
      align: 'center',
      width: '150px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Total GST Payment',
      dataIndex: 'totalGstPayment',
      key: 'totalGstPayment',
      align: 'center',
      width: '150px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
  ];


  return (
    <>
      <Helmet>
        <title> GstTask's | Gems Clip </title>
      </Helmet>

      <div className="row mt-4 client-wrapper">
        <SearchTable
          columns={columns}
          data={gstTaskData}
          searchItems={['feedback']}
          onRowClickHandler={onRowClickHandler}
        />
      </div>
      <DrawerGstTask show={show} setShow={setShow} userType={userType} rowValue={rowValue} />
    </>
  );
};

export default GstTask;