import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment';

const initialState = {
    loaderShow: false,
    id: null,
    pageName: "",
    headerCalendarPages: ['dashboard', 'gst-task', 'it-task', 'billing'],
    viewMonth: {
        month: moment(new Date()).format('MM'),
        year: moment(new Date()).format('YYYY'),
    }
};

const utilsSlice = createSlice({
    name: 'utils',
    initialState,
    reducers: {
        loaderShow(state) {
            return {
                ...state,
                loaderShow: true,
            }
        },
        loaderHide(state) {
            return {
                ...state,
                loaderShow: false,
            }
        },
        updateViewMonth(state, action) {
            return {
                ...state,
                viewMonth: {
                    month: moment(action.payload).format('MM'),
                    year: moment(action.payload).format('YYYY'),
                }
            }
        }
    },
})

export const { loaderShow, loaderHide, updateViewMonth } = utilsSlice.actions
export default utilsSlice.reducer