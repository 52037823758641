import React from 'react';
import { Tabs } from 'antd';
import SearchTable from '../../../components/Table/SearchTable';

const columns = [
  {
    title: 'S.No',
    dataIndex: 's.no',
    align: 'center',
    width: '50px',
    onCell: () => ({ className: '' }),
    render: (value, row, index) => (row.page - 1) * 10 + (index + 1),
    fixed: 'left',
  },
  {
    title: 'Month',
    dataIndex: 'month',
    key: 'month',
    align: 'center',
    width: '70px',
    onCell: () => ({ className: 'text-left ' }),
    render(text) {
      return <div>{text}</div>;
    },
    fixed: 'left',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: '130px',
    onCell: (record) => ({ className: ' ' }),
    render(text, record) {
      return <div>{text}</div>;
    },
  },
  {
    title: 'GSTR 2B',
    dataIndex: 'gstr2b',
    key: 'gstr2b',
    align: 'center',
    width: '150px',
    onCell: (record) => ({ className: ' ' }),
    render(text, record) {
      return <div>{text}</div>;
    },
  },
  {
    title: 'GST Value',
    dataIndex: 'gstValue',
    key: 'gstValue',
    align: 'center',
    width: '150px',
    onCell: (record) => ({ className: ' ' }),
    render(text, record) {
      return <div>{text}</div>;
    },
  },
];

const gstSource = [
  {
    month: 'Jan',
    status: 'A4 Incorp',
    services: 'GST, IT',
    mobileNo: '9789258234',
  },
  {
    month: 'Feb',
    status: 'Aiswaryalakshmi Auto Finance',
    services: 'Accounts writing	',
    mobileNo: '9976290102',
  },
  {
    month: 'MArch',
    status: 'Andavar Snacks	',
    services: 'GST',
    mobileNo: '8681032742',
  },
];

const items = [
  {
    key: '1',
    label: 'GST',
    children: <SearchTable data={gstSource} columns={columns} />

  },
  {
    key: '2',
    label: 'IT',
    children: 'Content of IT',
  },
  {
    key: '3',
    label: 'Accounts Writing',
    children: 'Content of Accounts Writing',
  },
  {
    key: '4',
    label: 'Firm Renewal',
    children: 'Content of Firm Renewal',
  },
  {
    key: '5',
    label: 'TDS',
    children: 'Content of TDS',
  },
];

const WorkSummary = ({ mode, clientType, data, formErrors, handleChange, handleSelectChangeHandler }) => (
  <>
    <div>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  </>
);

export default WorkSummary;
