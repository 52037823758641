import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Col, Drawer, Row, Space, Tabs } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { Confirmation, ShowMessage } from '../../components/Utils';
import General from './Tabs/General';
import LoginPage from './Tabs/LoginPage';
import Services from './Tabs/Services';
import WorkSummary from './Tabs/WorkSummary';
import FeeSummary from './Tabs/FeeSummary';
import { createClient, deleteClient, getClients, updateClient } from '../../STORE/MiddleWare/Clients';

const lastUpdateFields = [
  'lpGstPassword',
  'lpGstmailPassword',
  'lpPanPassword',
  'lpItPassword',
  'lpEwayPassword',
  'lpEInvoicePassword',
  'lpTdsPassword',
  'lpFirmPassword',
];

const DrawerClients = ({ show, setShow, clientType, rowValue }) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('view');
  const [data, setData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();

  const initData = {
    id: null,
    clientId: '',
    clientName: '',
    clientType: '',
    clientStatus: false,
    pan: '',
    gstIn: '',
    dsc: false,
    dscExpiryDate: '',
    glContactOwnerName: '',
    glContactMobileNo: '',
    glContactWhatsappNo: '',
    glContactEmailId: '',
    glContactPersonalEmailId: '',
    glContactAddressLine1: '',
    glContactAddressLine2: '',
    glContactCity: '',
    glContactDistrict: '',
    glContactPostalCode: '',
    glContactState: '',
    lpGstusername: '',
    lpGstPassword: '',
    lpGstPasswordChange: '',
    lpGstEmail: '',
    lpGstmailPassword: '',
    lpGstMobileNo: '',
    lpItpanNo: '',
    lpItpanName: '',
    lpItPassword: '',
    lpItPasswordChange: '',
    lpItmobileNo: '',
    lpItpanDob: '',
    lpItMail: '',
    lpEwayUsername: '',
    lpEwayPassword: '',
    lpEwayPasswordChange: '',
    lpAadhaarNo: '',
    lpAadhaarName: '',
    lpAadhaarDOB: '',
    lpAadhaarmobileNo: '',
    lpAadhaarpanLinkedStatus: '',
    lpEInvoiceusername: '',
    lpEInvoicePassword: '',
    lpEInvoiceEmail: '',
    lpEInvoiceMobile: '',
    lpTdsusername: '',
    lpTdsPassword: '',
    lpTdsEmail: '',
    lpTdsMobile: '',
    lpFirmusername: '',
    lpFirmPassword: '',
    lpFirmEmail: '',
    lpFirmMobile: '',
    serviceGstStatus: false,
    serviceGstType: '',
    serviceGststartingMonth: '',
    serviceGstfee: '',
    serviceItStatus: false,
    serviceItstartingYear: '',
    serviceItFee: '',
    serviceItSalaryCase: false,
    serviceAWStatus: false,
    serviceAWstartingYear: '',
    serviceAWFee: '',
    serviceDtsStatus: false,
    serviceDtsstartingYear: '',
    serviceDtsFee: '',
    serviceFirmStatus: false,
    serviceFirmstartingYear: '',
    serviceFirmFee: '',
  };

  const onClose = () => {
    setOpen(false);
    setShow(false);
    setMode('view');
  };
  useEffect(() => {
    setOpen(show);
    setFormErrors({});
    setMode(clientType === 'new' ? 'edit' : 'view');
    if (rowValue?._id) {
      setData({ ...rowValue });
    } else {
      setData(initData);
    }
  }, [show, rowValue, clientType]);

  const onDelete = () => {
    Confirmation('Are you sure, you want to delete this Client?', '', '', ['No', 'YES'], true).then((isConfirm) => {
      if (isConfirm) {
        const dsc = { _id: data._id };
        dispatch(deleteClient(dsc)).then((res) => {
          if (res?.payload?.message === 'Data Deleted Successfully') {
            ShowMessage('success', 'Client Deleted Successfully');
            dispatch(getClients());
            onClose();
          }
        });
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value) {
      setFormErrors({ ...formErrors, [name]: '' });
    }

    const updateobject = {};
    if (lastUpdateFields.findIndex((item) => item === name) >= 0) {
      updateobject[`${name}Change`] = moment(new Date()).format('YYYY-MM-DD hh:mm A');
    }
    updateobject[name] = value;

    setData({ ...data, ...updateobject });
  };

  const handleSelectChangeHandler = (nameField, valueField, valueObject) => {
    if (valueObject[valueField]) {
      setFormErrors({ ...formErrors, [nameField]: '' });
    }
    setData({ ...data, [nameField]: valueObject[valueField] });
  };
  const handleMultiSelectChangeHandler = (nameField, valueField, selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      setFormErrors({ ...formErrors, [nameField]: '' });
    }

    // Extract an array of selected values from the selected options
    const selectedValues = selectedOptions.map((option) => option[valueField]);

    setData({ ...data, [nameField]: selectedValues });
  };
  const handleToggleChangeHandler = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.clientName) {
      errors.clientName = 'Please Enter Client Name';
    }

    if (
      (values.pan && values.pan.length !== 10) ||
      (values.pan && !values.pan[5].match(/\d/)) ||
      (values.pan && !values.pan[8].match(/\d/))
    ) {
      errors.pan = 'Please Enter Valid PAN Number';
    }
    if (values.gstIn && values.gstIn.length !== 15 && !(values.gstIn === 0 || values.gstIn === '')) {
      errors.gstIn = 'Please Enter Valid GSTIN';
    }

    if (values.lpAadhaarNo && typeof values.lpAadhaarNo === 'string' && values.lpAadhaarNo.length !== 12) {
      errors.lpAadhaarNo = 'Please Enter Valid Aadhaar Number';
    }

    return errors;
  };

  const onSubmit = () => {
    const fieldErrors = validate(data);

    if (!_.isEmpty(fieldErrors)) {
      setFormErrors(fieldErrors);
      return;
    }

    const client = {
      clientId: data.clientId,
      clientName: data.clientName,
      clientType: data.clientType,
      clientStatus: data.clientStatus,
      services: data.services,
      pan: data.pan,
      gstIn: data.gstIn,
      dsc: data.dsc,
      dscExpiryDate: data.dscExpiryDate ? moment(data.dscExpiryDate).format('YYYY-MM-DD') : null,
      glContactOwnerName: data.glContactOwnerName,
      glContactMobileNo: data.glContactMobileNo,
      glContactWhatsappNo: data.glContactWhatsappNo,
      glContactEmailId: data.glContactEmailId,
      glContactPersonalEmailId: data.glContactPersonalEmailId,
      glContactAddressLine1: data.glContactAddressLine1,
      glContactAddressLine2: data.glContactAddressLine2,
      glContactCity: data.glContactCity,
      glContactDistrict: data.glContactDistrict,
      glContactPostalCode: data.glContactPostalCode,
      glContactState: data.glContactState,
      lpGstusername: data.lpGstusername,
      lpGstPassword: data.lpGstPassword,
      lpGstPasswordChange: data.lpGstPasswordChange
        ? moment(data.lpGstPasswordChange).format('YYYY-MM-DD hh:mm A')
        : null,
      lpGstEmail: data.lpGstEmail,
      lpGstmailPassword: data.lpGstmailPassword,
      lpGstMobileNo: data.lpGstMobileNo,
      lpGstmailPasswordChange: data.lpGstmailPasswordChange
        ? moment(data.lpGstmailPasswordChange).format('YYYY-MM-DD hh:mm A')
        : null,
      lpGstIn: data.gstIn,
      lpItpanNo: data.lpItpanNo,
      lpPanPassword: data.lpPanPassword,
      lpPanPasswordChange: data.lpPanPasswordChange
        ? moment(data.lpPanPasswordChange).format('YYYY-MM-DD hh:mm A')
        : null,
      lpItpanName: data.lpItpanName,
      lpItPassword: data.lpItPassword,
      lpItPasswordChange: data.lpItPasswordChange ? moment(data.lpItPasswordChange).format('YYYY-MM-DD hh:mm A') : null,
      lpItmobileNo: data.lpItmobileNo,
      lpItpanDob: data.lpItpanDob ? moment(data.lpItpanDob).format('YYYY-MM-DD hh:mm A') : null,
      lpItMail: data.lpItMail,
      lpEwayUsername: data.lpEwayUsername,
      lpEwayPassword: data.lpEwayPassword,
      lpEwayStatus: data.lpEwayStatus,
      lpEwayPasswordChange: data.lpEwayPasswordChange
        ? moment(data.lpEwayPasswordChange).format('YYYY-MM-DD hh:mm A')
        : null,
      lpAadhaarNo: data.lpAadhaarNo,
      lpAadhaarName: data.lpAadhaarName,
      lpAadhaarDOB: data.lpAadhaarDOB ? moment(data.lpAadhaarDOB).format('YYYY-MM-DD hh:mm A') : null,
      lpAadhaarmobileNo: data.lpAadhaarmobileNo,
      lpAadhaarpanLinkedStatus: data.lpAadhaarpanLinkedStatus,
      lpEInvoiceusername: data.lpEInvoiceusername,
      lpEInvoicePassword: data.lpEInvoicePassword,
      lpEInvoiceStatus: data.lpEInvoiceStatus,
      lpEInvoicePasswordChange: data.lpEInvoicePasswordChange
        ? moment(data.lpEInvoicePasswordChange).format('YYYY-MM-DD hh:mm A')
        : null,
      lpEInvoiceEmail: data.lpEInvoiceEmail,
      lpEInvoiceMobile: data.lpEInvoiceMobile,
      lpTdsusername: data.lpTdsusername,
      lpTdsPassword: data.lpTdsPassword,
      lpTdsPasswordChange: data.lpTdsPasswordChange
        ? moment(data.lpTdsPasswordChange).format('YYYY-MM-DD hh:mm A')
        : null,
      lpTdsEmail: data.lpTdsEmail,
      lpTdsMobile: data.lpTdsMobile,
      lpFirmusername: data.lpFirmusername,
      lpFirmPassword: data.lpFirmPassword,
      lpFirmPasswordChange: data.lpFirmPasswordChange
        ? moment(data.lpFirmPasswordChange).format('YYYY-MM-DD hh:mm A')
        : null,
      lpFirmEmail: data.lpFirmEmail,
      lpFirmMobile: data.lpFirmMobile,
      serviceGstStatus: data.serviceGstStatus,
      serviceGstType: data.serviceGstType,
      serviceGststartingMonth: data.serviceGststartingMonth
        ? moment(data.serviceGststartingMonth).format('YYYY-MM')
        : null,
      serviceGstfee: data.serviceGstfee,
      serviceItStatus: data.serviceItStatus,
      serviceItstartingYear: data.serviceItstartingYear,
      serviceItFee: data.serviceItFee,
      serviceItSalaryCase: data.serviceItSalaryCase,
      serviceAWStatus: data.serviceAWStatus,
      serviceAWstartingYear: data.serviceAWstartingYear,
      serviceAWFee: data.serviceAWFee,
      serviceDtsStatus: data.serviceDtsStatus,
      serviceDtsstartingYear: data.serviceDtsstartingYear
        ? moment(data.serviceDtsstartingYear).format('YYYY-MM')
        : null,
      serviceDtsFee: data.serviceDtsFee,
      serviceFirmStatus: data.serviceFirmStatus,
      serviceFirmstartingYear: data.serviceFirmstartingYear,
      serviceFirmFee: data.serviceFirmFee,
    };
    if (clientType === 'existing') {
      client._id = data._id;
      dispatch(updateClient(client)).then((res) => {
        if (res?.payload?.message === 'Data Updated Successfully') {
          ShowMessage('success', 'Client Updated Successfully');
          dispatch(getClients());
          onClose();
        } else {
          ShowMessage('error', 'Client Updation Failed');
        }
      });
    } else {
      dispatch(createClient(client)).then((res) => {
        if (res?.payload?.message === 'Data Created Successfully') {
          ShowMessage('success', 'Client Created Successfully');
          dispatch(getClients());
          onClose();
        } else {
          ShowMessage('error', 'Client Creation Failed');
        }
      });
    }
  };

  const items = [
    {
      key: '1',
      label: 'General',
      children: (
        <General
          mode={mode}
          clientType={clientType}
          data={data}
          formErrors={formErrors}
          handleChange={handleChange}
          handleSelectChangeHandler={handleSelectChangeHandler}
          handleToggleChangeHandler={handleToggleChangeHandler}
          handleMultiSelectChangeHandler={handleMultiSelectChangeHandler}
        />
      ),
    },
    {
      key: '2',
      label: 'Services',
      children: (
        <Services
          mode={mode}
          clientType={clientType}
          data={data}
          formErrors={formErrors}
          handleChange={handleChange}
          handleSelectChangeHandler={handleSelectChangeHandler}
          handleToggleChangeHandler={handleToggleChangeHandler}
        />
      ),
    },
    {
      key: '3',
      label: 'Credentials',
      children: (
        <LoginPage
          mode={mode}
          clientType={clientType}
          data={data}
          formErrors={formErrors}
          handleChange={handleChange}
          handleSelectChangeHandler={handleSelectChangeHandler}
          handleToggleChangeHandler={handleToggleChangeHandler}
        />
      ),
    },

    {
      key: '4',
      label: 'Work Summary',
      children: <WorkSummary />,
    },
    {
      key: '5',
      label: 'Fee Summary',
      children: <FeeSummary />,
    },
  ];

  const labels = [
    data?.serviceGstStatus && 'GST',
    data?.serviceItStatus && 'IT',
    data?.lpEwayStatus && 'E-Way',
    data?.lpEInvoiceStatus && 'E-Invoice',
    data?.serviceDtsStatus && 'TDS',
    data?.serviceFirmStatus && 'Firm Renewal',
    data?.serviceAWStatus && 'Accounts Writing',
  ].filter(Boolean);

  return (
    <>
      <Drawer
        title={clientType === 'new' ? 'Create a New Client' : 'Update Client'}
        width={720}
        onClose={onClose}
        open={open}
        style={{ body: { paddingBottom: 80, } }}
        extra={
          <Space>
            {mode === 'view' && clientType === 'existing' ? (
              <Button type="primary" onClick={() => setMode('edit')}>
                {' '}
                Edit{' '}
              </Button>
            ) : (
              <></>
            )}
            {mode === 'edit' && clientType === 'existing' ? (
              <>
                <Button type="primary" danger onClick={onDelete}>
                  Delete
                </Button>
              </>
            ) : (
              <></>
            )}
            {mode === 'edit' ? (
              <>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSubmit} type="primary">
                  Submit
                </Button>{' '}
              </>
            ) : (
              <></>
            )}
          </Space>
        }
      >
        <Card className="card custom-card">
          <Row>
            <Col span={24} className="mb-2 p-0 text-center">
              {data?.clientId}
            </Col>
            <Col span={24} className="mb-2 p-0 text-center">
              {data?.clientName}
            </Col>
            <Col span={12} className="mb-2 p-0 space-between">
              {data?.clientType}
            </Col>
            <Col span={12} className="mb-2 p-0 space-between">
              {[data?.glContactCity || '', data?.glContactDistrict || ''].filter(Boolean).join(', ')}
            </Col>
            <Col span={12} className="mb-2 p-0 space-between">
              {data?.glContactOwnerName}
            </Col>
            <Col span={12} className="space-between">
              {data?.glContactMobileNo}
            </Col>
            <Col span={24} className="mb-2 p-0 d-flex gap-2">
              {labels.map((service, index) => (
                <div key={index} className="p-2 rounded border custom-border">
                  {service}
                </div>
              ))}
            </Col>
          </Row>
        </Card>
        <Tabs defaultActiveKey="1" items={items} />
      </Drawer>
    </>
  );
};
export default DrawerClients;
