import { createAsyncThunk } from '@reduxjs/toolkit'

// Axios
import axios from "../../axios/index";
import urls from "../../axios/url";

// Get All Clients
export const getClients = createAsyncThunk('clients/getClients', async () => {
  try {
    const response = await axios.get(`${urls.clientUrl.url}`)
    return response.data
  } catch (err) {
    return err
  }
})

// Get All Client
export const getClient = createAsyncThunk('clients/getClient', async () => {
  try {
    const response = await axios.get(`${urls.clientUrl.url}`)
    return response.data
  } catch (err) {
    return err
  }
})

// Create Client
export const createClient = createAsyncThunk('clients/createClient', async (arg) => {
  try {
    const response = await axios.post(`${urls.clientUrl.url}`, arg);
    return response.data
  } catch (err) {
    return err
  }
})

// Update Client
export const updateClient = createAsyncThunk('clients/updateClient', async (arg) => {
  try {
    const response = await axios.patch(`${urls.clientUrl.url}/${arg._id}`, arg)
    return response.data
  } catch (err) {
    return err
  }
})

// Delete Client
export const deleteClient = createAsyncThunk('clients/deleteClient', async (arg) => {
  try {
    const response = await axios.delete(`${urls.clientUrl.url}/${arg._id}`, arg)
    return response.data
  } catch (err) {
    return err
  }
})