import React from 'react';
import { Collapse, Col, Form, Row, Space, Card } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import InputContainer from '../../../components/InputContainer/InputContainer';

const General = ({
  mode,
  clientType,
  data,
  formErrors,
  handleChange,
  handleSelectChangeHandler,
  handleToggleChangeHandler,
  handleMultiSelectChangeHandler,
}) => {
  
  const items = [
    {
      key: '1',
      label: 'Basic Details',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Client ID"
                  type="text"
                  id="clientId"
                  name="clientId"
                  value={data?.clientId}
                  onChange={handleChange}
                  placeholder="Please Enter Client ID"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  // containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
                  label="Client Name"
                  type="text"
                  id="clientName"
                  name="clientName"
                  value={data?.clientName}
                  onChange={handleChange}
                  error={formErrors?.clientName}
                  placeholder="Please Enter Client Name"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  required
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  label="Client Type"
                  type="select"
                  id="clientType"
                  name="clientType"
                  value={data?.clientType}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={handleSelectChangeHandler.bind(this, 'clientType', 'value')}
                  options={clientTypeOptions}
                  optionsValueKey="value"
                  optionsLabelKey="label"
                  placeholder="Select Client Type"
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Client Status"
                  type="toggle"
                  checkedChildren="Active"
                  unCheckedChildren="In Active"
                  id="clientStatus"
                  name="clientStatus"
                  value={data?.clientStatus}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(value) => handleToggleChangeHandler('clientStatus', value)}
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="PAN"
                  type="text"
                  id="pan"
                  name="pan"
                  value={data?.pan}
                  onChange={handleChange}
                  error={formErrors?.pan}
                  placeholder="Please Enter PAN"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="GSTIN"
                  type="text"
                  id="gstIn"
                  name="gstIn"
                  value={data?.gstIn}
                  onChange={handleChange}
                  error={formErrors?.gstIn}
                  placeholder="Please Enter GSTIN"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="DSC"
                  type="toggle"
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  id="dsc"
                  name="dsc"
                  value={data?.dsc}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={(value) => handleToggleChangeHandler('dsc', value)}
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              {data?.dsc ? (
                <Col span={12} className="mb-2">
                  <InputContainer
                    containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                    label="DSC Expiry Date"
                    type="date"
                    id="dscExpiryDate"
                    name="dscExpiryDate"
                    value={data?.dscExpiryDate}
                    onChange={handleChange}
                    placeholder="Please Enter DSC Expiry Date"
                    picker="date"
                    format="DD-MMM-YYYY"
                    disabled={!!(mode === 'view' && clientType === 'existing')}
                  />
                </Col>
              ) : (
                ''
              )}
            </Row>
          </Form>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Contact Details',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={24} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Client Name"
                  type="text"
                  id="glContactOwnerName"
                  name="glContactOwnerName"
                  value={data?.glContactOwnerName}
                  onChange={handleChange}
                  placeholder="Please Enter Client Name"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Mobile Number"
                  type="number"
                  id="glContactMobileNo"
                  name="glContactMobileNo"
                  value={data?.glContactMobileNo}
                  onChange={handleChange}
                  placeholder="Please Enter Mobile Number"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Whatsapp Number"
                  type="number"
                  id="glContactWhatsappNo"
                  name="glContactWhatsappNo"
                  value={data?.glContactWhatsappNo}
                  onChange={handleChange}
                  placeholder="Please Enter Whatsapp Number"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Registered Email ID"
                  type="text"
                  id="glContactEmailId"
                  name="glContactEmailId"
                  value={data?.glContactEmailId}
                  onChange={handleChange}
                  placeholder="Please Enter Registered Email ID"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Personal Email ID"
                  type="text"
                  id="glContactPersonalEmailId"
                  name="glContactPersonalEmailId"
                  value={data?.glContactPersonalEmailId}
                  onChange={handleChange}
                  placeholder="Please Enter Personal Email ID"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Company Address Line 1"
                  type="text"
                  id="glContactAddressLine1"
                  name="glContactAddressLine1"
                  value={data?.glContactAddressLine1}
                  onChange={handleChange}
                  placeholder="Please Enter Company Address Line 1"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Company Address Line 2"
                  type="text"
                  id="glContactAddressLine2"
                  name="glContactAddressLine2"
                  value={data?.glContactAddressLine2}
                  onChange={handleChange}
                  placeholder="Please Enter Company Address Line 2"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="City"
                  type="text"
                  id="glContactCity"
                  name="glContactCity"
                  value={data?.glContactCity}
                  onChange={handleChange}
                  placeholder="Please Enter City"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="District"
                  type="text"
                  id="glContactDistrict"
                  name="glContactDistrict"
                  value={data?.glContactDistrict}
                  onChange={handleChange}
                  placeholder="Please Enter District"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="State"
                  type="text"
                  id="glContactState"
                  name="glContactState"
                  value={data?.glContactState}
                  onChange={handleChange}
                  placeholder="Please Enter State"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Postal Code"
                  type="number"
                  id="glContactPostalCode"
                  name="glContactPostalCode"
                  value={data?.glContactPostalCode}
                  onChange={handleChange}
                  placeholder="Please Enter Postal Code"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
    {
      key: '3',
      label: 'Aadhaar',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Aadhaar No"
                  type="number"
                  id="lpAadhaarNo"
                  name="lpAadhaarNo"
                  value={data?.lpAadhaarNo}
                  error={formErrors?.lpAadhaarNo}
                  onChange={handleChange}
                  placeholder="Please Enter Aadhaar No"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Aadhaar Name"
                  type="text"
                  id="lpAadhaarName"
                  name="lpAadhaarName"
                  value={data?.lpAadhaarName}
                  onChange={handleChange}
                  placeholder="Please Enter Aadhaar Name"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Aadhaar DOB"
                  type="date"
                  id="lpAadhaarDOB"
                  name="lpAadhaarDOB"
                  value={data?.lpAadhaarDOB}
                  onChange={handleChange}
                  placeholder="Please Choose Aadhaar DOB"
                  picker="date"
                  format="DD-MMM-YYYY"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  label="Aadhaar Mobile No"
                  type="number"
                  id="lpAadhaarmobileNo"
                  name="lpAadhaarmobileNo"
                  value={data?.lpAadhaarmobileNo}
                  onChange={handleChange}
                  placeholder="Please Enter Aadhaar Mobile No"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={mode === 'view' && clientType === 'existing' ? 'custom-disable' : ''}
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  label="Aadhaar-PAN Link Status"
                  type="select"
                  id="lpAadhaarpanLinkedStatus"
                  name="lpAadhaarpanLinkedStatus"
                  value={data?.lpAadhaarpanLinkedStatus}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={handleSelectChangeHandler.bind(this, 'lpAadhaarpanLinkedStatus', 'value')}
                  options={lpAadhaarpanLinkedStatusOptions}
                  optionsValueKey="value"
                  optionsLabelKey="label"
                  placeholder="Select Aadhaar-PAN Link Status"
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
  ];

  return (
    <Space direction="vertical">
      <Collapse
        accordion
        items={items}
        defaultActiveKey={['1', '2']}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
      />
    </Space>
  );
};
export default General;


// Client Type Options
const clientTypeOptions = [
  { value: 'Individual-IT', label: 'Individual-IT' },
  { value: 'Proprietor', label: 'Proprietor' },
  { value: 'Partnership', label: 'Partnership' },
  { value: 'Private Ltd', label: 'Private Ltd' },
  { value: 'Public Ltd', label: 'Public Ltd' },
  { value: 'Trust', label: 'Trust' },
  { value: 'Co-operative', label: 'Co-operative' },
];

const lpAadhaarpanLinkedStatusOptions = [
  { value: 'Aadhaar-PAN Linked', label: 'Aadhaar-PAN Linked' },
  { value: 'Aadhaar-PAN Not Linked', label: 'Aadhaar-PAN Not Linked' },
  { value: 'Check Status', label: 'Check Status' },
];