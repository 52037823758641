const urls = {
  login: {
    url: 'api/auth/signin',
  },
  userUrl: {
    url: 'api/users',
  },
  clientUrl: {
    url: 'api/clients',
  },
  updateUserPassword: {
    url: 'api/users/update-password',
  },
  panUrl: {
    url: 'api/pan',
  },
  dscUrl: {
    url: 'api/dsc',
  },
  dueDatesUrl: {
    url: 'api/dueDates',
  },
  dueDatesHistoryUrl: {
    url: 'api/dueDatesHistory',
  },
  gstTaskUrl: {
    url: 'api/gstTask',
  },
};

export default urls;
