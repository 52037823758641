import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import '../css/DueDates.scss';
import { UseDueDatesData, UseDueDatesMaster } from '../STORE/Selector/DueDates';
import { getAllDueDates } from '../STORE/MiddleWare/DueDates';
import DrawerDues from '../sections/dueDates/DrawerDues';
import SearchTable from '../components/Table/SearchTable';

// create a component
const DueDates = () => {

    const [dueData, setDueData] = useState([]);
    const [show, setShow] = useState(false);
    const [drawerType, setDrawerType] = useState("new");
    const [rowValue, setRowValue] = useState({});
    const dispatch = useDispatch();

    const DueDates = UseDueDatesData();
    const DueDatesMaster = UseDueDatesMaster();

    useEffect(() => {
        dispatch(getAllDueDates());
    }, []);

    useEffect(() => {
        setDueData(DueDates?.dueDateHistory);
    }, [DueDates]);

    const onRowClickHandler = (e, value) => {
        setShow(true);
        setDrawerType("existing")
        setRowValue(value);
    };

    const onNewClickHandler = () => {
        setShow(true);
        setDrawerType("new")
        setRowValue(DueDatesMaster);
    };

    const addNewPan = {
        modalId: 'showModal',
        label: 'Due Dates Master',
        onClick: onNewClickHandler,
    };

    const columns = [
        {
            title: 'S.No',
            dataIndex: 's.no',
            align: 'center',
            width: '70px',
            onCell: () => ({ className: 'min-width-70-px' }),
            render: (value, row, index) => (row.page - 1) * 10 + (index + 1),
        },
        {
            title: 'Month',
            dataIndex: 'month',
            align: 'center',
            width: '150px',
            onCell: () => ({ className: 'min-width-150-px' }),
            render(text, row) {
                return text ? <div>{formatMonthYear(row)}</div> : <></>;
            },
            filters: frameFilter(dueData),
            onFilter: (value, record) => formatMonthYear(record) === value,
            sorter: (a, b) => formatMonthYearToTime(a) - formatMonthYearToTime(b),
        },
        {
            title: 'GSTR 1',
            dataIndex: 'gstr1OpeningDate',
            key: 'gstr1OpeningDate',
            align: 'center',
            width: '150px',
            onCell: () => ({ className: ' min-width-300-px' }),
            render(text, row) {
                return text ? <div>{`${moment.localeData().ordinal(parseInt(row.gstr1OpeningDate, 10))} - ${moment.localeData().ordinal(parseInt(row.gstr1DueDate, 10))}`}</div> : <></>;
            },
        },
        {
            title: 'GSTR-2B',
            dataIndex: 'gstr2BOpeningDate',
            key: 'gstr2BOpeningDate',
            align: 'center',
            width: '150px',
            onCell: () => ({ className: ' min-width-300-px' }),
            render(text, row) {
                return text ? <div>{`${moment.localeData().ordinal(parseInt(row.gstr2BOpeningDate, 10))} - ${moment.localeData().ordinal(parseInt(row.gstr2BDueDate, 10))}`}</div> : <></>;
            },
        },
        {
            title: 'GSTR-3B',
            dataIndex: 'gstr3BOpeningDate',
            key: 'gstr3BOpeningDate',
            align: 'center',
            width: '250px',
            onCell: () => ({ className: ' min-width-300-px' }),
            render(text, row) {
                return text ? <div>{`${moment.localeData().ordinal(parseInt(row.gstr3BOpeningDate, 10))} - ${moment.localeData().ordinal(parseInt(row.gstr3BDueDate, 10))}`}</div> : <></>;
            },
        },
        {
            title: 'GSTR-4',
            dataIndex: 'gstr4OpeningDate',
            key: 'gstr4OpeningDate',
            align: 'center',
            width: '250px',
            onCell: () => ({ className: ' min-width-300-px' }),
            render(text, row) {
                return text ? <div>{`${moment.localeData().ordinal(parseInt(row.gstr4OpeningDate, 10))} - ${moment.localeData().ordinal(parseInt(row.gstr4DueDate, 10))}`}</div> : <></>;
            },
        },
        {
            title: 'ITR - Individual',
            dataIndex: 'itrIndividualOpeningDate',
            key: 'itrIndividualOpeningDate',
            align: 'center',
            width: '250px',
            onCell: () => ({ className: ' min-width-300-px' }),
            render(text, row) {
                return text ? <div>{`${row.itrIndividualOpeningDate} - ${row.itrIndividualDueDate}`}</div> : <></>;
            },
        },
        {
            title: 'ITR - Company',
            dataIndex: 'itrCompanyOpeningDate',
            key: 'itrCompanyOpeningDate',
            align: 'center',
            width: '250px',
            onCell: () => ({ className: ' min-width-300-px' }),
            render(text, row) {
                return text ? <div>{`${row.itrCompanyOpeningDate} - ${row.itrCompanyDueDate}`}</div> : <></>;
            },
        },
        {
            title: 'TDS',
            dataIndex: 'tdsOpeningDate',
            key: 'tdsOpeningDate',
            align: 'center',
            width: '250px',
            onCell: () => ({ className: ' min-width-300-px' }),
            render(text, row) {
                return text ? <div>{`${moment.localeData().ordinal(parseInt(row.tdsOpeningDate, 10))} / ${moment.localeData().ordinal(parseInt(row.tdsDueDate, 10))}`}</div> : <></>;
            },
        },
        {
            title: 'Firm Renewal',
            dataIndex: 'firmrenewalOpeningDate',
            key: 'firmrenewalOpeningDate',
            align: 'center',
            width: '250px',
            onCell: () => ({ className: ' min-width-300-px' }),
            render(text, row) {
                return text ? <div>{`${row.firmrenewalOpeningDate} - ${row.firmrenewalDueDate}`}</div> : <></>;
            },
        },
    ]

    return (
        <>
            <Helmet>
                <title> Due Dates | Gems Clip </title>
            </Helmet>

            <div className="row mt-4 due-date-wrapper">
                <SearchTable
                    columns={columns}
                    data={dueData}
                    searchItems={['gstr1OpeningDate', 'gstr1DueDate']}
                    addNewPopup={addNewPan}
                    onRowClickHandler={onRowClickHandler}
                />
            </div>

            <DrawerDues show={show} setShow={setShow} drawerType={drawerType} rowValue={rowValue} />
        </>
    );
}

export default DueDates;


const frameFilter = (array) => {
    const res = [];
    array?.forEach((item) => res.push({ "text": `${moment(`${item?.month}-${item?.year}`, "MM-YYYY").format("MMMM YYYY")}`, "value": `${moment(`${item?.month}-${item?.year}`, "MM-YYYY").format("MMMM YYYY")}` }))
    return _.uniqBy(res, 'value');
}

const formatMonthYear = (row) => moment(`${row?.month}-${row?.year}`, "MM-YYYY").format("MMMM YYYY")

const formatMonthYearToTime = (row) => new Date(`${row?.year}-${row?.month}`).getTime();