import React from 'react';
import moment from 'moment';
import { Collapse, Col, Form, Row, Space } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import InputContainer from '../../../components/InputContainer/InputContainer';

const LoginPage = ({ mode, clientType, data, handleChange }) => {
  const containerClass = mode === 'view' && clientType === 'existing' ? 'custom-disable' : '';
  const items = [
    {
      key: '1',
      label: 'GST',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="GST User Name"
                  type="text"
                  id="lpGstusername"
                  name="lpGstusername"
                  value={data?.lpGstusername}
                  onChange={handleChange}
                  placeholder="Please Enter GST User Name"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="GST Password"
                  type="password"
                  id="lpGstPassword"
                  name="lpGstPassword"
                  value={data?.lpGstPassword}
                  onChange={handleChange}
                  placeholder="Please Enter GST Password"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                  subtext={`${data?.lpGstPasswordChange ? `Last Updated on : ${moment(data?.lpGstPasswordChange).format('YYYY-MM-DD hh:mm A')}` : ''}`}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="GST Email"
                  type="text"
                  id="lpGstEmail"
                  name="lpGstEmail"
                  value={data?.lpGstEmail}
                  onChange={handleChange}
                  placeholder="Please Enter GST Email"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="GST Mail Password"
                  type="password"
                  id="lpGstmailPassword"
                  name="lpGstmailPassword"
                  value={data?.lpGstmailPassword}
                  onChange={handleChange}
                  placeholder="Please Enter GST Mail Password"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                  subtext={`${
                    data?.lpGstmailPasswordChange ? `Last Updated on : ${moment(data?.lpGstmailPasswordChange).format('YYYY-MM-DD hh:mm A')}` : ''
                  }`}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="GST Mobile No."
                  type="number"
                  id="lpGstMobileNo"
                  name="lpGstMobileNo"
                  value={data?.lpGstMobileNo}
                  onChange={handleChange}
                  placeholder="Please Enter Mobile Number"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="GSTIN"
                  type="text"
                  id="gstIn"
                  name="gstIn"
                  value={data?.gstIn}
                  onChange={handleChange}
                  placeholder="Please Enter GSTIN"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
    {
      key: '2',
      label: 'IT',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="PAN No"
                  type="number"
                  id="lpItpanNo"
                  name="lpItpanNo"
                  value={data?.lpItpanNo}
                  onChange={handleChange}
                  placeholder="Please Enter PAN No"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="PAN Password"
                  type="password"
                  id="lpPanPassword"
                  name="lpPanPassword"
                  value={data?.lpPanPassword}
                  onChange={handleChange}
                  placeholder="Please Enter PAN Password"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                  subtext= {`${
                    data?.lpPanPasswordChange ? `Last Updated on : ${moment(data?.lpPanPasswordChange).format('YYYY-MM-DD hh:mm A')}` : ''
                  }`}
                 
                  
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="PAN Name"
                  type="text"
                  id="lpItpanName"
                  name="lpItpanName"
                  value={data?.lpItpanName}
                  onChange={handleChange}
                  placeholder="Please Enter PAN Name"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="PAN DOB"
                  type="date"
                  id="lpItpanDob"
                  name="lpItpanDob"
                  value={data?.lpItpanDob}
                  onChange={handleChange}
                  placeholder="Please Choose PAN DOB"
                  picker="date"
                  format="DD-MMM-YYYY"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>

              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="IT Mobile No"
                  type="number"
                  id="lpItmobileNo"
                  name="lpItmobileNo"
                  value={data?.lpItmobileNo}
                  onChange={handleChange}
                  placeholder="Please Enter IT Mobile No"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>

              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="IT Mail"
                  type="text"
                  id="lpItMail"
                  name="lpItMail"
                  value={data?.lpItMail}
                  onChange={handleChange}
                  placeholder="Please Enter IT Mail"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="IT Mail Password"
                  type="password"
                  id="lpItPassword"
                  name="lpItPassword"
                  value={data?.lpItPassword}
                  onChange={handleChange}
                  placeholder="Please Enter IT Mail Password"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                  subtext= {`${
                    data?.lpItPasswordChange ? `Last Updated on : ${moment(data?.lpItPasswordChange).format('YYYY-MM-DD hh:mm A')}` : ''
                  }`}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
    {
      key: '3',
      label: 'E-Way',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="E-Way Username"
                  type="text"
                  id="lpEwayUsername"
                  name="lpEwayUsername"
                  value={data?.lpEwayUsername}
                  onChange={handleChange}
                  placeholder="Please Enter E-Way Username"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="E-Way Password"
                  type="password"
                  id="lpEwayPassword"
                  name="lpEwayPassword"
                  value={data?.lpEwayPassword}
                  onChange={handleChange}
                  placeholder="Please Enter E-Way Password"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                  subtext= {`${
                    data?.lpEwayPasswordChange ? `Last Updated on : ${moment(data?.lpEwayPasswordChange).format('YYYY-MM-DD hh:mm A')}` : ''
                  }`}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
   
    {
      key: '4',
      label: 'E-Invoice',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="User Name"
                  type="text"
                  id="lpEInvoiceusername"
                  name="lpEInvoiceusername"
                  value={data?.lpEInvoiceusername}
                  onChange={handleChange}
                  placeholder="Please Enter User Name"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="Password"
                  type="password"
                  id="lpEInvoicePassword"
                  name="lpEInvoicePassword"
                  value={data?.lpEInvoicePassword}
                  onChange={handleChange}
                  placeholder="Please Enter Password"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                  subtext= {`${
                    data?.lpEInvoicePasswordChange ? `Last Updated on : ${moment(data?.lpEInvoicePasswordChange).format('YYYY-MM-DD hh:mm A')}` : ''
                  }`}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="Registered Email"
                  type="text"
                  id="lpEInvoiceEmail"
                  name="lpEInvoiceEmail"
                  value={data?.lpEInvoiceEmail}
                  onChange={handleChange}
                  placeholder="Please Enter Registered Email"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="Registered Mobile"
                  type="number"
                  id="lpEInvoiceMobile"
                  name="lpEInvoiceMobile"
                  value={data?.lpEInvoiceMobile}
                  onChange={handleChange}
                  placeholder="Please Enter Registered Mobile"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
    {
      key: '5',
      label: 'TDS',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="TDS ID"
                  type="text"
                  id="lpTdsusername"
                  name="lpTdsusername"
                  value={data?.lpTdsusername}
                  onChange={handleChange}
                  placeholder="Please Enter TDS ID"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="TDS Password"
                  type="password"
                  id="lpTdsPassword"
                  name="lpTdsPassword"
                  value={data?.lpTdsPassword}
                  onChange={handleChange}
                  placeholder="Please Enter TDS Password"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                  subtext= {`${
                    data?.lpTdsPasswordChange ? `Last Updated on : ${moment(data?.lpTdsPasswordChange).format('YYYY-MM-DD hh:mm A')}` : ''
                  }`}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="TDS Mail"
                  type="text"
                  id="lpTdsEmail"
                  name="lpTdsEmail"
                  value={data?.lpTdsEmail}
                  onChange={handleChange}
                  placeholder="Please Enter TDS Mail"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>

              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="TDS Mobile No"
                  type="number"
                  id="lpTdsMobile"
                  name="lpTdsMobile"
                  value={data?.lpTdsMobile}
                  onChange={handleChange}
                  placeholder="Please Enter TDS Mobile No"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
    {
      key: '6',
      label: 'Firm Renewal',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="User Name"
                  type="text"
                  id="lpFirmusername"
                  name="lpFirmusername"
                  value={data?.lpFirmusername}
                  onChange={handleChange}
                  placeholder="Please Enter User Name"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="Password"
                  type="password"
                  id="lpFirmPassword"
                  name="lpFirmPassword"
                  value={data?.lpFirmPassword}
                  onChange={handleChange}
                  placeholder="Please Enter Password"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                  showClipboard
                  subtext= {`${
                    data?.lpFirmPasswordChange ? `Last Updated on : ${moment(data?.lpFirmPasswordChange).format('YYYY-MM-DD hh:mm A')}` : ''
                  }`}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="Registered Email"
                  type="text"
                  id="lpFirmEmail"
                  name="lpFirmEmail"
                  value={data?.lpFirmEmail}
                  onChange={handleChange}
                  placeholder="Please Enter Registered Email"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="Registered Mobile"
                  type="number"
                  id="lpFirmMobile"
                  name="lpFirmMobile"
                  value={data?.lpFirmMobile}
                  onChange={handleChange}
                  placeholder="Please Enter Registered Mobile"
                  disabled={!!(mode === 'view' && clientType === 'existing')}
                />
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
  ];

const serviceGstStatus = data?.serviceGstStatus; 
const serviceItStatus = data?.serviceItStatus;
const lpEwayStatus=data?.lpEwayStatus;
const lpEInvoiceStatus=data?.lpEInvoiceStatus;
const serviceDtsStatus=data?.serviceDtsStatus;
const serviceFirmStatus =data?.serviceFirmStatus;

  return (
    <Space direction="vertical">
      <Collapse
        accordion
        items={items.filter(obj =>
          (serviceGstStatus && obj.label === 'GST') ||
          (serviceItStatus && obj.label === 'IT')||
          (lpEwayStatus&&obj.label==='E-Way')||
          (lpEInvoiceStatus&&obj.label==='E-Invoice')||
          (serviceDtsStatus&&obj.label==='TDS')||
          (serviceFirmStatus&& obj.label==='Firm Renewal')
        )}
        defaultActiveKey={['1', '2', '3', '4', '5', '6']}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
      />
    </Space>
  );
};


export default LoginPage;





