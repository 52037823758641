import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
});

/**
 For Further understanding please refer the below url
  https://www.thedutchlab.com/insights/using-axios-interceptors-for-refreshing-your-api-token
*/

// Request interceptor for API calls
axiosClient.interceptors.request.use(
  async config => {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    config.headers = {
      'Authorization': `Bearer ${user?.token}`,
      'Accept': 'application/json',
      'Content-Type': config.headers["Content-Type"] || "application/json"
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });

// Response interceptor for API calls
// axiosClient.interceptors.response.use((response) => response, async (error) => {
//   const originalRequest = error.config;
//   if (error.response.status === 403 && !originalRequest._retry) {
//     originalRequest._retry = true;
//     const access_token = await refreshAccessToken();
//     axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
//     return axiosClient(originalRequest);
//   }
//   return Promise.reject(error);
// });

export default axiosClient;
