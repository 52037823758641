import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Slice/Auth';
import clientsReducer from './Slice/Clients';
import usersReducer from './Slice/Users';
import utilsReducer from './Slice/Utils';
import PanReducer from './Slice/Pan';
import DscReducer from './Slice/Dsc';

import dueDatesReducer from './Slice/DueDates';
import gstTasksReducer from './Slice/GstTask';

const rootReducer = combineReducers({
  auth: authReducer,
  clients: clientsReducer,
  utils: utilsReducer,
  users: usersReducer,
  pan: PanReducer,
  dsc: DscReducer,
  dueDates: dueDatesReducer,
  gstTasks: gstTasksReducer,
});

export default configureStore({
  reducer: rootReducer,
});
