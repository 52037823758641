import { Button, Dropdown, Space } from 'antd';

const DrawerButtons = ({ mode, setMode, userType, onClose, onSubmitHandler, onDeleteHandler }) => {
    const items = [
        {
            key: '1',
            label: 'Submit and Move to Next Step',
            primary: true,
        },
    ];

    return (
        <Space>
            {mode === 'view' && userType === 'existing' ? (
                <Button type="primary" onClick={() => setMode('edit')}>
                    Edit
                </Button>
            ) : (
                <></>
            )}
            {mode === 'edit' && userType === 'existing' ? (
                <Button type="primary" danger onClick={onDeleteHandler}>
                    Delete
                </Button>
            ) : (
                <></>
            )}
            {mode === 'edit' ? (
                <>
                    <Button onClick={onClose}>Cancel</Button>
                    <Dropdown.Button
                        type="primary"
                        menu={{
                            items,
                            onClick: () => onSubmitHandler(true),
                        }}
                        onClick={() => onSubmitHandler(false)}
                    >
                        Submit
                    </Dropdown.Button>
                </>
            ) : (
                <></>
            )}
        </Space>
    )
}

export default DrawerButtons;