import React, { useState, useEffect } from 'react';
import { Button, Drawer, Select, Space, Collapse } from 'antd';
import { useDispatch } from 'react-redux';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';

import { createPan, deletePan, getPans, updatePan } from '../../STORE/MiddleWare/Pans';
import { Confirmation, ShowMessage } from '../../components/Utils';

import ClientDetails from './Sections/ClientDetails';
import AcknowledgementDetails from './Sections/AcknowledgementDetails';
import SendingDetails from './Sections/SendingDetails';
import PANDetails from './Sections/PANDetails';

// eslint-disable-next-line react/prop-types
const DrawerPans = ({ show, setShow, drawerType, rowValue }) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('view');
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();

  const [data, setData] = useState({});

  const initData = {
    id: null,
    applicationDate: '',
    clientName: '',
    dob: '',
    aadhaarNo: '',
    panCardType: '',
    clientMobile: '',
    applicationType: '',
    identity: '',
    feeCollected: '',
    sentDate: '',
    via: '',
    to: '',
    courierTrackerId: '',
    panAcknowledgement: '',
    pan: '',
    panDeliveredDate: '',
    remarks: '',
    status: 'Application to be sent',
  };

  useEffect(() => {
    setOpen(show);
    setFormErrors({});
    setMode(drawerType === 'new' ? 'edit' : 'view');
    if (rowValue?._id) {
      setData({ ...rowValue });
    } else {
      setData(initData);
    }
  }, [show, rowValue, drawerType]);

  const onClose = () => {
    setOpen(false);
    setShow(false);
    setMode('view');
  };

  const onDelete = () => {
    Confirmation('Are you sure, you want to delete this PAN?', '', '', ['No', 'YES'], true).then((isConfirm) => {
      if (isConfirm) {
        const pan = { _id: data._id };
        dispatch(deletePan(pan)).then((res) => {
          if (res?.payload?.message === 'Data Deleted Successfully') {
            ShowMessage('success', 'PAN Deleted Successfully');
            dispatch(getPans());
            onClose();
          }
        });
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value) {
      setFormErrors({ ...formErrors, [name]: '' });
    }

    const updatedData = SetStatusHandler({ ...data, [name]: value });

    setData({ ...updatedData });
  };

  const SetStatusHandler = (data) => {
    const updatedFields = {
      SentDate: data.sentDate,
      Via: data.via,
      to: data.to,
      courierTrackerId: data.courierTrackerId,
    };
    const areAllFieldsFilled = Object.values(updatedFields).every((field) => field || field?.length > 0);

    if (areAllFieldsFilled) {
      data.status = 'Application Sent';
    }
    if (data.panAcknowledgement) {
      data.status = 'Acknowledgement Received';
    }
    if (data.panDeliveredDate) {
      data.status = 'PAN Card Delivered';
    }
    return data;
  };

  const handleSelectChangeHandler = (nameField, valueField, valueObject) => {
    if (valueObject[valueField]) {
      setFormErrors({ ...formErrors, [nameField]: '' });
    }

    setData({ ...data, [nameField]: valueObject[valueField] });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.clientName) {
      errors.clientName = 'Please Enter the Client Name';
    }
    if (!values.panCardType) {
      errors.panCardType = 'Please Select PAN Card Type';
    }
    if (!values.applicationType) {
      errors.applicationType = 'Please Select Application Type';
    }
    if (!values.status) {
      errors.status = 'Please Select the Status';
    }
    return errors;
  };

  const onSubmit = () => {
    const fieldErrors = validate(data);

    if (!_.isEmpty(fieldErrors)) {
      setFormErrors(fieldErrors);
      return;
    }

    const pan = {
      applicationDate: data.applicationDate ? moment(data.applicationDate).format('YYYY-MM-DD') : null,
      clientName: data.clientName,
      dob: data.dob ? moment(data.dob).format('YYYY-MM-DD') : null,
      aadhaarNo: data.aadhaarNo,
      panCardType: data.panCardType,
      clientMobile: data.clientMobile,
      applicationType: data.applicationType,
      identity: data.identity,
      feeCollected: data.feeCollected,
      sentDate: data.sentDate ? moment(data.sentDate).format('YYYY-MM-DD') : null,
      via: data.via,
      to: data.to,
      courierTrackerId: data.courierTrackerId,
      panAcknowledgement: data.panAcknowledgement,
      pan: data.pan,
      panDeliveredDate: data.panDeliveredDate ? moment(data.panDeliveredDate).format('YYYY-MM-DD') : null,
      remarks: data.remarks,
      status: data.status,
    };
    if (drawerType === 'existing') {
      pan._id = data._id;
      dispatch(updatePan(pan)).then((res) => {
        if (res?.payload?.message === 'Data Updated Successfully') {
          ShowMessage('success', 'PAN Updated Successfully');
          dispatch(getPans());
          onClose();
        } else {
          ShowMessage('error', res?.payload?.response?.data?.validation?.body?.message || 'Something went wrong');
        }
      });
    } else {
      dispatch(createPan(pan)).then((res) => {
        if (res?.payload?.message === 'Data Created Successfully') {
          ShowMessage('success', 'PAN Created Successfully');
          dispatch(getPans());
          onClose();
        } else {
          ShowMessage('error', res?.payload?.response?.data?.validation?.body?.message || 'Something went wrong');
        }
      });
    }
  };

  return (
    <>
      <Drawer
        title={drawerType === 'existing' ? 'Update PAN' : 'Create a New PAN'}
        width={720}
        onClose={onClose}
        open={open}
        style={{ body: { paddingBottom: 80, } }}
        extra={
          <Space>
            {mode === 'view' ? (
              <Button type="primary" onClick={() => setMode('edit')}>
                Edit
              </Button>
            ) : (
              <></>
            )}
            {mode === 'edit' ? (
              <>
                {drawerType === 'existing' ? (
                  <Button type="primary" danger onClick={onDelete}>
                    Delete
                  </Button>
                ) : (
                  <></>
                )}
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSubmit} type="primary">
                  Submit
                </Button>
              </>
            ) : (
              <></>
            )}
          </Space>
        }
      >
        <div>
          <ClientDetails
            mode={mode}
            drawerType={drawerType}
            data={data}
            formErrors={formErrors}
            handleChange={handleChange}
          />
          <PANDetails
            mode={mode}
            drawerType={drawerType}
            data={data}
            formErrors={formErrors}
            handleChange={handleChange}
            handleSelectChangeHandler={handleSelectChangeHandler}
          />
          <SendingDetails
            mode={mode}
            drawerType={drawerType}
            data={data}
            formErrors={formErrors}
            handleChange={handleChange}
          />
          <AcknowledgementDetails
            mode={mode}
            drawerType={drawerType}
            data={data}
            formErrors={formErrors}
            handleChange={handleChange}
            handleSelectChangeHandler={handleSelectChangeHandler}
          />
        </div>
      </Drawer>
    </>
  );
};
export default DrawerPans;
