/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useRef } from 'react';
import { Button, Drawer, Form, Row, Steps, } from 'antd';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import _ from 'lodash';

import { Confirmation, ShowMessage } from '../../components/Utils';

import InputContainer from '../../components/InputContainer/InputContainer';
import DrawerButtons from './DrawerButtons';
import { createGstTask, deleteGstTask, getGstTasks, updateGstTask } from '../../STORE/MiddleWare/GstTask';
import { UseViewMonth } from '../../STORE/Selector/Utils';

// CSS
import '../../css/GstTask.scss';

const modeOfPayment = [
  { label: 'Cash', value: 'Cash' },
  { label: 'UPI', value: 'UPI' },
  { label: 'Bank Transfer', value: 'Bank Transfer' },
];

const reconciliationOptions = [
  {
    value: 'DataMatching',
    label: 'Data is Matching',
  },
  {
    value: 'DataNotMatching',
    label: 'Data is Not Matching',
  },
];

const billTypeOptions = [
  {
    value: 'counterParty',
    label: 'Counter Party Not Filed',
  },
  {
    value: 'billMissing',
    label: 'Bill Missing',
  },
];

const initData = {
  id: null,
  feedback: [],
  feedbackDescription: '',
  remainderDate: '',
  nilReturn: '',
  dataCollectedDate: '',
  tallyEntryDate: '',
  acknowledgementNo: '',
  gstr1FilingDate: '',
  gstr2bReconciliation: 'DataMatching',
  gstr2bBillType: '',
  gstPayable: '',
  taxValue: '',
  fee: '',
  penaltyAndInterest: '',
  totalGstPayment: '',
  taxCollected: '',
  feeCollected: '',
  collectionDate: '',
  modeOfPayment: null,
  gstr3AcknowledgementNo: '',
  gstr3FiledDate: '',
  downloadDate: '',
  clientId: '',
  step: 0,
  nextStep: false,
};

const emptyBill = [
  {
    billNo: '',
    billDate: '',
    gstIn: '',
    partyName: '',
    billValue: '',
  },
]
// eslint-disable-next-line react/prop-types
const DrawerGstTask = ({ show, setShow, userType, rowValue }) => {
  const dispatch = useDispatch();
  const clipboardRef = useRef();

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('view');
  const [formErrors, setFormErrors] = useState({});
  const [currentStatus, setCurrentStatus] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [bills, setBills] = useState(emptyBill);

  const [dataInput, setDataInput] = useState({});

  const CurrentViewMonth = UseViewMonth();

  const onClose = () => {
    setOpen(false);
    setShow(false);
    setMode('view');
  };

  useEffect(() => {
    setOpen(show);
    setFormErrors({});
    setMode(userType === 'new' ? 'edit' : 'view');
    if (rowValue?._id) {
      setDataInput({ ...rowValue });
      setCurrentStatus(rowValue?.step);
      setSelectedStatus(rowValue?.step);
      setBills(rowValue.gstr2bBills)
    } else {
      setDataInput(initData);
      setBills(emptyBill);
    }
  }, [show, userType, rowValue]);

  const handleOnChange = (value) => {
    if (currentStatus < value) return;
    setSelectedStatus(value);
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    if (value) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
    setDataInput({ ...dataInput, [name]: value });
  };

  const selectChangeHandler = (nameField, valueField, valueObject) => {
    if (valueObject[valueField]) {
      setFormErrors({ ...formErrors, [nameField]: '' });
    }
    setDataInput({ ...dataInput, [nameField]: valueObject[valueField] });
  };

  const toggleChangeHandler = (name, value) => {
    setDataInput({ ...dataInput, [name]: value });
  };


  const BillDateChangeHandler = (index, e) => {
    const { name, value } = e.target;

    const existingBill = [...bills];

    existingBill[index][name] = value
    setBills([...existingBill]);
  }

  const BillChangeHandler = (e, index) => {
    const { name, value } = e.target;

    const existingBill = [...bills];

    existingBill[index][name] = value;
    setBills([...existingBill]);
  };

  const newBillHandler = () => {
    const newBill = {
      billNo: '',
      billDate: '',
      gstIn: '',
      partyName: '',
      billValue: '',
    };
    setBills([...bills, newBill]);
  };

  const deleteBillHandler = (index) => {
    setBills([...bills.toSpliced(index, 1)]);
  };

  const validate = (values) => {
    const errors = {};

    if (values.step === 0 && !values.dataCollectedDate) {
      errors.dataCollectedDate = 'Please choose Data Collected Date';
    }

    if (values.step === 1 && !values.tallyEntryDate) {
      errors.tallyEntryDate = 'Please choose Tally Entry Date';
    }

    if (values.step === 2 && !values.acknowledgementNo) {
      errors.acknowledgementNo = 'Please Enter Acknowledgement No';
    }

    if (values.step === 2 && !values.gstr1FilingDate) {
      errors.gstr1FilingDate = 'Please Choose GSTR 1 Filing Date';
    }

    if (values.step === 3 && !values.gstr2bReconciliation) {
      errors.gstr2bReconciliation = 'Please Choose GSTR 2B Reconciliation';
    }

    if (values.step === 3 && values.gstr2bReconciliation && values.gstr2bReconciliation === "DataNotMatching" && !values.gstr2bBillType) {
      errors.gstr2bBillType = 'Please Choose GSTR-2B Bill Type';
    }

    if (values.step === 4 && !values.gstPayable) {
      errors.gstPayable = 'Please Enter GST Payable';
    }

    if (values.step === 5 && !values.fee) {
      errors.fee = 'Please Enter Fee';
    }

    if (values.step === 5 && !values.penaltyAndInterest) {
      errors.penaltyAndInterest = 'Please Enter Penalty And Interest';
    }

    if (values.step === 5 && !values.totalGstPayment) {
      errors.totalGstPayment = 'Please Enter Total GST Payment';
    }
    if (values.step === 6 && !values.taxCollected) {
      errors.taxCollected = 'Please Enter Tax Collected';
    }

    if (values.step === 6 && !values.feeCollected) {
      errors.feeCollected = 'Please Enter Fee Collected';
    }

    if (values.step === 6 && !values.collectionDate) {
      errors.collectionDate = 'Please Choose Collection Date';
    }

    if (values.step === 6 && !values.modeOfPayment) {
      errors.modeOfPayment = 'Please Choose Mode of Payment';
    }

    if (values.step === 7 && !values.gstr3AcknowledgementNo) {
      errors.gstr3AcknowledgementNo = 'Please Enter GSTR3 Acknowledgement No';
    }

    if (values.step === 7 && !values.gstr3FiledDate) {
      errors.gstr3FiledDate = 'Please choose GSTR3 Filed Date';
    }

    if (values.step === 8 && !values.downloadDate) {
      errors.downloadDate = 'Please choose Download Date';
    }

    return errors;
  };

  const onSubmitHandler = (nextStep = false) => {
    if (nextStep) {
      const fieldErrors = validate(dataInput);

      if (!_.isEmpty(fieldErrors)) {
        setFormErrors(fieldErrors);
        return;
      }
    } else {
      setFormErrors({});
    }

    const GstTaskData = {
      feedbackDescription: dataInput.feedbackDescription,
      remainderDate: dataInput.remainderDate ? moment(dataInput.remainderDate).format('YYYY-MM-DD') : null,
      nilReturn: dataInput.nilReturn,
      dataCollectedDate: dataInput.dataCollectedDate ? moment(dataInput.dataCollectedDate).format('YYYY-MM-DD') : null,
      tallyEntryDate: dataInput.tallyEntryDate ? moment(dataInput.tallyEntryDate).format('YYYY-MM-DD') : null,
      acknowledgementNo: dataInput.acknowledgementNo,
      gstr1FilingDate: dataInput.gstr1FilingDate ? moment(dataInput.gstr1FilingDate).format('YYYY-MM-DD') : null,
      gstr2bReconciliation: dataInput.gstr2bReconciliation,
      gstr2bBillType: dataInput.gstr2bBillType,
      gstr2bBills: bills,
      gstPayable: dataInput.gstPayable,
      taxValue: dataInput.gstPayable, // Both Tax Value and GST Payable is same
      fee: dataInput.fee,
      penaltyAndInterest: dataInput.penaltyAndInterest,
      totalGstPayment: dataInput.totalGstPayment,
      taxCollected: dataInput.taxCollected,
      feeCollected: dataInput.feeCollected,
      collectionDate: dataInput.collectionDate ? moment(dataInput.collectionDate).format('YYYY-MM-DD') : null,
      modeOfPayment: dataInput.modeOfPayment,
      gstr3AcknowledgementNo: dataInput.gstr3AcknowledgementNo,
      gstr3FiledDate: dataInput.gstr3FiledDate ? moment(dataInput.gstr3FiledDate).format('YYYY-MM-DD') : null,
      downloadDate: dataInput.downloadDate ? moment(dataInput.downloadDate).format('YYYY-MM-DD') : null,
      step: dataInput.step,
      nextStep,
      clientId: dataInput.clientId,
    };

    if (userType === 'existing') {
      GstTaskData._id = dataInput._id;
      dispatch(updateGstTask(GstTaskData)).then((res) => {
        if (res?.payload?.message === 'Data Updated Successfully') {
          ShowMessage('success', 'GstTask Updated Successfully');
          setBills(emptyBill);
          dispatch(getGstTasks({ month: CurrentViewMonth?.month, year: CurrentViewMonth?.year }));
          onClose();
        }
      });
    } else {
      dispatch(createGstTask(GstTaskData)).then((res) => {
        if (res?.payload?.message === 'Data Created Successfully') {
          ShowMessage('success', 'GstTask Created Successfully');
          setBills(emptyBill);
          dispatch(getGstTasks({ month: CurrentViewMonth?.month, year: CurrentViewMonth?.year }));
          onClose();
        }
      });
    }
  };

  const onDeleteHandler = () => {
    Confirmation('Are you sure, you want to delete this GstTask?', '', '', ['No', 'YES'], true).then((isConfirm) => {
      if (isConfirm) {
        const GstTaskData = { _id: dataInput._id };
        dispatch(deleteGstTask(GstTaskData)).then((res) => {
          if (res?.payload?.message === 'Data Deleted Successfully') {
            ShowMessage('success', 'GstTask Deleted Successfully');
            setBills(emptyBill);
            dispatch(getGstTasks({ month: CurrentViewMonth?.month, year: CurrentViewMonth?.year }));
            onClose();
          }
        });
      }
    });
  };

  const CopyToClipboardHandler = () => {
    navigator.clipboard.writeText(clipboardRef?.current.innerHTML);
    ShowMessage('success', `Copied to Clipboard Successfully`);
  };

  const items = [
    {
      title: 'Contact the Client',
      description: (
        <>
          {(selectedStatus === 0 || currentStatus === 0) && (
            <div className="d-flex flex-wrap">
              <div className="d-block w-100">
                {dataInput?.feedback?.map((item) => (
                  <div key={item?.reminderDate} className="feedback-card">
                    <div>
                      <p>{item?.notes}</p>
                      <p className="reminder-content">{moment(item?.reminderDate).format('YYYY-MM-DD hh:mm A')}</p>
                    </div>
                  </div>
                ))}
              </div>
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-12`}
                label="Feedback"
                type="textarea"
                id="feedbackDescription"
                name="feedbackDescription"
                value={dataInput?.feedbackDescription}
                error={formErrors?.feedbackDescription}
                onChange={inputChangeHandler}
                placeholder="Please Enter Feedback"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 0)}
              />
              <div className='col-md-12'>
                <InputContainer
                  containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                    } pe-3 pb-3 col-md-6`}
                  label="Remainder Date"
                  format="DD-MMM-YYYY"
                  type="date"
                  picker="date"
                  id="remainderDate"
                  name="remainderDate"
                  value={dataInput?.remainderDate}
                  error={formErrors?.remainderDate}
                  onChange={inputChangeHandler}
                  placeholder="Please Choose Remainder Date"
                  disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 0)}
                />

              </div>
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Nil Return"
                type="toggle"
                checkedChildren="Yes"
                unCheckedChildren="No"
                id="nilReturn"
                name="nilReturn"
                value={dataInput?.nilReturn}
                onChange={(value) => toggleChangeHandler('nilReturn', value)}
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 0)}
              />
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Data Collected Date"
                format="DD-MMM-YYYY"
                type="date"
                picker="date"
                id="dataCollectedDate"
                name="dataCollectedDate"
                value={dataInput?.dataCollectedDate}
                error={formErrors?.dataCollectedDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Data Collected Date"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 0)}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Tally Entry',
      description: (
        <>
          {(selectedStatus === 1 || currentStatus === 1) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Tally Entry Date"
                format="DD-MMM-YYYY"
                type="date"
                picker="date"
                id="tallyEntryDate"
                name="tallyEntryDate"
                value={dataInput?.tallyEntryDate}
                error={formErrors?.tallyEntryDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Tally Entry Date"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 1)}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'GSTR-1 Filing',
      description: (
        <>
          {(selectedStatus === 2 || currentStatus === 2) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Acknowledgement Number"
                type="text"
                id="acknowledgementNo"
                name="acknowledgementNo"
                value={dataInput?.acknowledgementNo}
                error={formErrors?.acknowledgementNo}
                onChange={inputChangeHandler}
                placeholder="Please Enter Acknowledgement Number"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 2)}
              />
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="GSTR-1 Filing Date"
                format="DD-MMM-YYYY"
                type="date"
                picker="date"
                id="gstr1FilingDate"
                name="gstr1FilingDate"
                value={dataInput?.gstr1FilingDate}
                error={formErrors?.gstr1FilingDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose GSTR-1 Filing Date"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 2)}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'GSTR-2B Reconciliation',
      description: (
        <>
          {(selectedStatus === 3 || currentStatus === 3) && (
            <div className="mb-2">
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-12`}
                inputClassName="d-flex"
                type="radio"
                id="gstr2bReconciliation"
                name="gstr2bReconciliation"
                value={dataInput?.gstr2bReconciliation}
                error={formErrors?.gstr2bReconciliation}
                onChange={inputChangeHandler}
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 3)}
                options={reconciliationOptions}
              />
              {dataInput?.gstr2bReconciliation === 'DataNotMatching' && (
                <div className="mt-2">
                  <div className="d-inline-flex w-100">
                    <InputContainer
                      containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                        } pe-3 pb-3 col-md-12`}
                      inputClassName="d-flex"
                      label="Bill Type"
                      type="radio"
                      id="gstr2bBillType"
                      name="gstr2bBillType"
                      value={dataInput?.gstr2bBillType}
                      error={formErrors?.gstr2bBillType}
                      onChange={inputChangeHandler}
                      disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 3)}
                      options={billTypeOptions}
                    />
                  </div>
                  {dataInput?.gstr2bBillType && (
                    <>
                      {bills?.map((item, index) => (
                        <>
                          <div className="d-flex flex-wrap" key={index}>
                            <InputContainer
                              containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                                } pe-3 pb-3 col-md-6`}
                              label="Bill No"
                              type="text"
                              id="billNo"
                              name="billNo"
                              value={item.billNo}
                              onChange={(e) => BillChangeHandler(e, index)}
                              placeholder="Please Enter Bill No"
                              disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 3)}
                            />
                            <InputContainer
                              containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                                } pe-3 pb-3 col-md-6`}
                              label="Bill Date"
                              type="date"
                              id="billDate"
                              name="billDate"
                              format="DD-MMM-YYYY"
                              value={item.billDate}
                              onChange={BillDateChangeHandler.bind("this", index)}
                              placeholder="Please Choose Bill Date"
                              disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 3)}
                            />
                            <InputContainer
                              containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                                } pe-3 pb-3 col-md-6`}
                              label="GSTIN"
                              type="text"
                              id="gstIn"
                              name="gstIn"
                              value={item.gstIn}
                              onChange={(e) => BillChangeHandler(e, index)}
                              placeholder="Please Enter GSTIN"
                              disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 3)}
                            />
                            <InputContainer
                              containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                                } pe-3 pb-3 col-md-6`}
                              label="Party Name"
                              type="text"
                              id="partyName"
                              name="partyName"
                              value={item.partyName}
                              onChange={(e) => BillChangeHandler(e, index)}
                              placeholder="Please Enter Party Name"
                              disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 3)}
                            />
                            <InputContainer
                              containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                                } pe-3 pb-3 col-md-6`}
                              label="Bill Value"
                              type="number"
                              id="billValue"
                              name="billValue"
                              value={item.billValue}
                              onChange={(e) => BillChangeHandler(e, index)}
                              placeholder="Please Enter Bill Value"
                              disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 3)}
                            />
                            {mode === "edit" ?
                              <div className="col-md-6 d-flex align-items-center justify-content-end">
                                <Button
                                  type="primary"
                                  danger
                                  className="mt-2"
                                  onClick={() => deleteBillHandler(index)}
                                >
                                  Remove
                                </Button>
                              </div>
                              : <></>}
                          </div>
                          <hr />
                        </>
                      ))}
                      {mode === "edit" ? <Button type="primary" className="mt-2" onClick={newBillHandler}>
                        Add New Bill
                      </Button>
                        : <></>}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      ),
    },
    {
      title: 'GSTR-3B Upload',
      description: (
        <>
          {(selectedStatus === 4 || currentStatus === 4) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="GST Payable"
                type="number"
                id="gstPayable"
                name="gstPayable"
                value={dataInput?.gstPayable}
                error={formErrors?.gstPayable}
                onChange={inputChangeHandler}
                placeholder="Please Enter GST Payable"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 4)}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Inform the Payment',
      description: (
        <>
          {(selectedStatus === 5 || currentStatus === 5) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Tax Value"
                type="text"
                id="taxValue"
                name="taxValue"
                value={dataInput?.gstPayable}
                error={formErrors?.gstPayable}
                onChange={inputChangeHandler}
                placeholder="Please Enter Tax Value"
                disabled
              />
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Fee"
                type="number"
                id="fee"
                name="fee"
                value={dataInput?.fee}
                error={formErrors?.fee}
                onChange={inputChangeHandler}
                placeholder="Please Enter Fee"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 5)}
              />
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Penalty & Interest"
                type="number"
                id="penaltyAndInterest"
                name="penaltyAndInterest"
                value={dataInput?.penaltyAndInterest}
                error={formErrors?.penaltyAndInterest}
                onChange={inputChangeHandler}
                placeholder="Please Enter Penalty & Interest"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 5)}
              />
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Total GST Payment"
                type="number"
                id="totalGstPayment"
                name="totalGstPayment"
                value={dataInput?.totalGstPayment}
                error={formErrors?.totalGstPayment}
                onChange={inputChangeHandler}
                placeholder="Please Enter Total GST Payment"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 5)}
              />
              <div className="pe-3 pb-3 w-100">
                <div className="clipboard-section">
                  <div ref={clipboardRef}>
                    <p>
                      **Client name**, <br />
                      &emsp;&emsp;Your GST return for the month **Filing month** has been filed.
                      <br />
                      &emsp;&emsp;GST Payable is Rs. {dataInput?.gstPayable}
                      <br />
                      &emsp;&emsp;Fee for GST filing is Rs. {dataInput?.fee}
                      <br />
                      &emsp;&emsp;Penalty Rs. {dataInput?.penaltyAndInterest} (if crossing the due date)
                      <br />
                      &emsp;&emsp;Interest Rs. {dataInput?.penaltyAndInterest} (if crossing the due date)
                      <br />
                      &emsp;&emsp;Total amount is Rs. {dataInput?.totalGstPayment}
                      <br />
                      &emsp;&emsp;Kindly, make the payment shortly to avoid the penality charges.
                      <br />
                      <br />- Gems Clip Audit Services
                    </p>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faCopy} className="clipboard-icon" onClick={CopyToClipboardHandler} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Collection of the Payment',
      description: (
        <>
          {(selectedStatus === 6 || currentStatus === 6) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Tax Collected"
                type="number"
                id="taxCollected"
                name="taxCollected"
                value={dataInput?.taxCollected}
                error={formErrors?.taxCollected}
                onChange={inputChangeHandler}
                placeholder="Please Enter Tax Collected"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 6)}
              />
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Fee Collected"
                type="number"
                id="feeCollected"
                name="feeCollected"
                value={dataInput?.feeCollected}
                error={formErrors?.feeCollected}
                onChange={inputChangeHandler}
                placeholder="Please Enter Fee Collected"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 6)}
              />
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Collection Date"
                format="DD-MMM-YYYY"
                picker="date"
                type="date"
                id="collectionDate"
                name="collectionDate"
                value={dataInput?.collectionDate}
                error={formErrors?.collectionDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Collection Date"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 6)}
              />
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Mode of Payment"
                type="select"
                id="modeOfPayment"
                name="modeOfPayment"
                value={dataInput?.modeOfPayment}
                error={formErrors?.modeOfPayment}
                onChange={selectChangeHandler.bind(this, 'modeOfPayment', 'value')}
                options={modeOfPayment}
                optionsValueKey="value"
                optionsLabelKey="label"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 6)}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'GSTR-3B Filing',
      description: (
        <>
          {(selectedStatus === 7 || currentStatus === 7) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Acknowledgement Number"
                type="text"
                id="gstr3AcknowledgementNo"
                name="gstr3AcknowledgementNo"
                value={dataInput?.gstr3AcknowledgementNo}
                error={formErrors?.gstr3AcknowledgementNo}
                onChange={inputChangeHandler}
                placeholder="Please Enter Acknowledgement Number"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 7)}
              />
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Filed Date"
                type="date"
                format="DD-MMM-YYYY"
                picker="date"
                id="gstr3FiledDate"
                name="gstr3FiledDate"
                value={dataInput?.gstr3FiledDate}
                error={formErrors?.gstr3FiledDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Filed Date"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 7)}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Challan Download',
      description: (
        <>
          {(selectedStatus === 8 || currentStatus === 8) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName={`${mode === 'view' && userType === 'existing' ? 'custom-disable' : ''
                  } pe-3 pb-3 col-md-6`}
                label="Download Date"
                type="date"
                format="DD-MMM-YYYY"
                picker="date"
                id="downloadDate"
                name="downloadDate"
                value={dataInput?.downloadDate}
                error={formErrors?.downloadDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Download Date"
                disabled={!!((mode === 'view' && userType === 'existing') || currentStatus !== 8)}
              />
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <Drawer
      title={userType === 'new' ? 'Create a New GST Task' : 'Update GST Task'}
      width={720}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <DrawerButtons
          mode={mode}
          setMode={setMode}
          userType={userType}
          onDeleteHandler={onDeleteHandler}
          onClose={onClose}
          onSubmitHandler={onSubmitHandler}
        />
      }
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Steps current={currentStatus} onChange={handleOnChange} direction="vertical" percent={50} items={items} />
        </Row>
      </Form>
    </Drawer>
  );
};
export default DrawerGstTask;
