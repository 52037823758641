import { createSlice } from '@reduxjs/toolkit'
import { getAllDueDates, getDueDate } from '../MiddleWare/DueDates';

const initialState = {
    dueDatesData: [],
    dueDatesMaster: {},
    dueDates: null
};

const dueDatesSlice = createSlice({
    name: 'dueDates',
    initialState,
    reducers: {
        setDueDates(state, action) {
            return {
                ...state,
                dueDatesData: action.payload ? action.payload.company : [],
            };
        },
        updateDueDates(state, action) {
            const oldData = [...state.dueDatesData];
            const newdata = oldData.filter((due) => due._id !== action.payload._id);
            newdata.push(action.payload);

            return {
                ...state,
                dueDatesData: newdata || [],
            };
        },
        createDueDates(state, action) {
            const newdata = [...state.dueDatesData];
            newdata.push(action.payload);

            return {
                ...state,
                dueDatesData: newdata || [],
            };
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getAllDueDates.pending, () => { })

        builder.addCase(getAllDueDates.fulfilled, (state, action) => {
            if (action.payload) {
                state.dueDatesData = action.payload
                state.dueDatesMaster = action.payload?.dueDateMaster || {}
            }
        })

        builder.addCase(getAllDueDates.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload.message
                state.loading = true;
            }
        })

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getDueDate.pending, () => { })

        builder.addCase(getDueDate.fulfilled, (state, action) => {
            if (action.payload) {
                state.dueDates = action.payload
            }
        })

        builder.addCase(getDueDate.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload.message
                state.loading = true;
            }
        })
    }
})

export const { createDueDate, setDueDate, updateDueDate } = dueDatesSlice.actions
export default dueDatesSlice.reducer