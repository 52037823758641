import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';

// CSS
import '../css/Pans.scss';
import { UsePans } from '../STORE/Selector/Pan';
import { getPans } from '../STORE/MiddleWare/Pans';
import DrawerPans from '../sections/pans/DrawerPans';
import SearchTable from '../components/Table/SearchTable';
import { frameFilterArray } from '../components/Utils';

const Pans = () => {
  const dispatch = useDispatch();
  // State
  const [pansData, setPansData] = useState([]);
  const [show, setShow] = useState(false);
  const [drawerType, setDrawerType] = useState("new");
  const [rowValue, setRowValue] = useState({});

  // Custom Selector Hooks
  const pans = UsePans();

  useEffect(() => {
    dispatch(getPans());
  }, []);

  useEffect(() => {
    setPansData(pans?.pan);
  }, [pans]);

  const onRowClickHandler = (e, value) => {
    setShow(true);
    setDrawerType("existing")
    setRowValue(value);
  };

  const onNewClickHandler = () => {
    setShow(true);
    setDrawerType("new")
    setRowValue({});
  };

  const addNewPan = {
    modalId: 'showModal',
    label: 'New PAN',
    onClick: onNewClickHandler,
  };


  const columns = [
    {
      title: 'S.No',
      dataIndex: 's.no',
      align: 'center',
      width: '70px',
      onCell: () => ({ className: '' }),
      render: (value, row, index) => (row.page - 1) * 10 + (index + 1),
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      align: 'center',
      width: '250px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(pansData, "clientName"),
      onFilter: (value, record) => record.clientName === value,
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
    },
    {
      title: 'Application Type',
      dataIndex: 'applicationType',
      key: 'applicationType',
      align: 'center',
      width: '130px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(pansData, "applicationType"),
      onFilter: (value, record) => record.applicationType === value,
      sorter: (a, b) => a.applicationType.localeCompare(b.applicationType),
    },
    {
      title: 'PAN Card Type',
      dataIndex: 'panCardType',
      key: 'panCardType',
      align: 'center',
      width: '200px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(pansData, "panCardType"),
      onFilter: (value, record) => record.panCardType === value,
      sorter: (a, b) => a.panCardType.localeCompare(b.panCardType),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '150px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div><span className={StatusClassNameHandler(text)}>{text}</span></div>;
      },
      filters: frameFilterArray(pansData, "status"),
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
  ];

  return (
    <>
      <Helmet>
        <title> PANs | Gems Clip </title>
      </Helmet>

      <div className="row mt-3 pan-wrapper">
        <SearchTable
          columns={columns}
          data={pansData}
          searchItems={['clientName', 'aadhaarNo']}
          addNewPopup={addNewPan}
          onRowClickHandler={onRowClickHandler}
        />
      </div>

      <DrawerPans show={show} setShow={setShow} drawerType={drawerType} rowValue={rowValue} />
    </>
  );
};
export default Pans;

const StatusClassNameHandler = (value) => {
  if (value === "PAN card delivered") {
    return "status-completed"
  }

  if (value) {
    return "status-pending";
  }
  return null;
}