import { createAsyncThunk } from '@reduxjs/toolkit'

// Axios
import axios from "../../axios/index";
import urls from "../../axios/url";

// Get DueDate
export const getDueDate = createAsyncThunk('dueDate/getDueDate', async (arg, thunkAPI) => {
    try {
        const response = await axios.get(`${urls.dueDatesHistoryUrl.url}`)
        return response.data
    } catch (err) {
        return err
    }
})

// Get All DueDate
export const getAllDueDates = createAsyncThunk('dueDate/getAllDueDates', async (arg, thunkAPI) => {
    try {
        const response = await axios.get(`${urls.dueDatesHistoryUrl.url}`)
        return response.data
    } catch (err) {
        return err
    }
})

// Create DueDate
export const createDueDate = createAsyncThunk('dueDate/createDueDate', async (arg, thunkAPI) => {
    try {
        const response = await axios.post(`${urls.dueDatesUrl.url}`, arg);
        return response.data
    } catch (err) {
        return err
    }
})

// Update DueDate
export const updateDueDate = createAsyncThunk('dueDate/updateDueDate', async (arg, thunkAPI) => {
    try {
        const response = await axios.patch(`${urls.dueDatesUrl.url}/${arg._id}`, arg)
        return response.data
    } catch (err) {
        return err
    }
})

// Update DueDate History
export const updateDueDateHistory = createAsyncThunk('dueDate/updateDueDateHistory', async (arg, thunkAPI) => {
    try {
        const response = await axios.patch(`${urls.dueDatesHistoryUrl.url}/${arg._id}`, arg)
        return response.data
    } catch (err) {
        return err
    }
})

// Delete DueDate
export const deleteDueDate = createAsyncThunk('dueDate/deleteDueDate', async (arg, thunkAPI) => {
    try {
        const response = await axios.delete(`${urls.dueDatesHistoryUrl.url}/${arg._id}`, arg)
        return response.data
    } catch (err) {
        return err
    }
})